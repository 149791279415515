import { FC, useState, useCallback } from 'react';
import { NavLink } from "react-router-dom";

const SpineMenu: FC = (): JSX.Element => {
  const [isToggle, setToggle] = useState<boolean>(false);
  const onToggleIconClick = useCallback(() => {
    setToggle((prev) => !prev)
  }, [setToggle]);

  return (
    <div className="wk-spine-container">
      <nav data-testid="spine-menu-nav" className={`wk-spine-nav ${isToggle ? 'wk-is-spine-expanded-fixed' : ''}`}>
        <ul className="wk-spine-list">
          <li className="wk-spine-list-item">
            <NavLink className="wk-spine-link wk-spine-navigation-item-top-level" to="/migration-overview" activeClassName="wk-is-active">
              <span title="View Migration Status" className="wk-icon-list-bullet-square wk-spine-link-icon">
              </span>
              <span className="wk-spine-link-text">Migration Overview</span>
            </NavLink>
          </li>
        </ul>
        <div className="wk-spine-footer">
          <button data-testid="spine-menu-toggle-button" className="wk-button wk-button-icon wk-spine-activator" type="button" onClick={onToggleIconClick}>
            <span className={`wk-icon-chevron-double-right ${isToggle ? 'wk-icon-chevron-double-left' : ''}`} aria-hidden="true"></span>
            <span className="wk-sr-only">Toggle spine</span>
          </button>
        </div>
      </nav>
    </div>
  )
}

export default SpineMenu
