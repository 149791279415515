import { FC } from 'react';
import { User } from '../../models/user';
import Fishbowl from '../Fishbowl/Fishbowl';

interface HeaderProps {
  user: User | undefined;
}

const Header: FC<HeaderProps> = ({ user }): JSX.Element => (
  <div className="wk-banner wk-fixed-top">
    <div className="wk-banner-container">
      <div className="wk-banner-left-content">
        <div className="wk-logo-container">
          <img src="https://cdn.wolterskluwer.io/wk/fundamentals/1.x.x/logo/assets/wheel-medium.svg"
            alt="Wolters Kluwer logo" />
        </div>
        <div className="wk-banner-product-name">
          Apollo Migration & Publishing Dashboard
            </div>
      </div>
      <div className="wk-banner-right-content">
        <Fishbowl user={user}/>
      </div>
    </div>
  </div>
);

export default Header;
