import { FC, createRef } from 'react';
import api from '../../api';
import { PROJECT_STATUSES } from '../../models/project-statuses.model';
import { Project } from '../../models/project.model';
import { Pubvol } from '../../models/pubvol.model';

interface DataTableProps {
  data: Project[];
  onSelectionChange: (items: Project[]) => void
}

const DataTable: FC<DataTableProps> = ({ data, onSelectionChange }): JSX.Element => {

  const tooltips: { [key: string]: HTMLDivElement } = {};
  const tableSection = createRef<HTMLDivElement>();

  const setTooltipReference = (index: number, key: string, element: HTMLDivElement): void => {
    tooltips[`${key}-${index}`] = element;
  }

  const toggleTooltip = (key: string, index: number): void => {
    tooltips[`${key}-${index}`].classList.toggle('wk-tooltip-open')
  }

  const selectAll = (): void => {
    if (data.some(el => !el.selected)) {
      data.forEach(el => el.selected = true);
    } else {
      data.forEach(el => el.selected = false);
    }
    onSelectionChange(data.filter(el => el.selected));
  }

  const onItemSelect = (index: number): void => {
    data[index].selected = !data[index].selected;
    onSelectionChange(data.filter(el => el.selected));
  }

  const downloadLogFile = (path: string): void => {
    api.getLogFile(path).then(async (res: { file: Promise<Blob>, name: string }) => {
      const encodedData = window.URL.createObjectURL(await res.file);
      const downloadLink = document.createElement('a');

      downloadLink.style.display = 'none';
      (tableSection.current as HTMLDivElement).appendChild(downloadLink);
      downloadLink.setAttribute("href", encodedData);
      downloadLink.setAttribute("download", res.name);
      downloadLink.click();

      (tableSection.current as HTMLDivElement).removeChild(downloadLink);
    });
  }

  const getNestedValuesByKey = (project: Project, key: keyof Pubvol): string => {
    const uniqueValues = new Set(project.pubvols.map(el => el[key]));
    const arrayOfValues = Array.from(uniqueValues);
    return arrayOfValues.join(', ');
  }

  const getIconPosition = (index: number): string => {
    return index < data.length - 1 ? 'wk-position-bottom' : 'wk-position-top';
  }

  const switchIcon = (project: Project, i: number): JSX.Element | null => {
    switch (project.importStatus) {
      case PROJECT_STATUSES.SUCCESS:
        return <div data-testid="success">
          <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('circle', i)}
            onMouseLeave={() => toggleTooltip('circle', i)} className="wk-icon-check-circle icon-green icon-bold"
            aria-hidden="true">
          </span>
          <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'circle')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
            <div className="wk-tooltip-body">
              Migrated successfully
            </div>
          </div>
        </div>;
      case PROJECT_STATUSES.NOT_MIGRATED:
        return <div data-testid="not-migrated">
          <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('clock', i)}
            onMouseLeave={() => toggleTooltip('clock', i)} className="wk-icon-clock icon-dark icon-bold"
            aria-hidden="true">
          </span>
          <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'clock')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
            <div className="wk-tooltip-body">
              Migration not yet started
            </div>
          </div>
        </div>;
      case PROJECT_STATUSES.IN_PROGRESS:
        return <div data-testid="in-progress">
          <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('clock', i)}
            onMouseLeave={() => toggleTooltip('clock', i)} className="wk-icon-infinity icon-yellow icon-bold"
            aria-hidden="true">
          </span>
          <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'clock')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
            <div className="wk-tooltip-body">
              Migration in progress
            </div>
          </div>
        </div>;
      case PROJECT_STATUSES.ERROR:
        return <>
          <div className="error" data-testid="error">
            <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('stop', i)}
              onMouseLeave={() => toggleTooltip('stop', i)} className="wk-icon-close-circle icon-red icon-bold"
              aria-hidden="true">
            </span>
            <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'stop')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
              <div className="wk-tooltip-body">
                Migration failed
            </div>
            </div>
          </div>
          <div className="error">
            <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('visible', i)}
              onMouseLeave={() => toggleTooltip('visible', i)} className="wk-icon-visible icon-blue"
              aria-hidden="true">
            </span>
            <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'visible')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
              <div className="wk-tooltip-body">
                View
            </div>
            </div>
          </div>
          <div className="error" data-testid="download-button" onClick={() => downloadLogFile(project.name)}>
            <span data-testid="tooltip-trigger" onMouseOver={() => toggleTooltip('download', i)}
              onMouseLeave={() => toggleTooltip('download', i)} className="wk-icon-download-line icon-blue"
              aria-hidden="true">
            </span>
            <div data-testid="tooltip" ref={setTooltipReference.bind(this, i, 'download')} className={`wk-tooltip wk-tooltip-description ${getIconPosition(i)}`}>
              <div className="wk-tooltip-body">
                Download error log
            </div>
            </div>
          </div>
        </>
      default: return null;
    }
  }

  return (
    <div className="table-section" ref={tableSection}>
      <table className="wk-table wk-table-permit-overflow">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col">
              <label className="wk-field-choice-label">
                <input data-testid="select-all" onChange={selectAll.bind(this)} checked={data.length > 0 && data.every(el => el.selected)}
                  type="checkbox" name="name1" value="option-1" className="wk-field-choice" />
                <span className="wk-field-choice-text">File path</span>
              </label>
            </th>
            <th scope="col">Pubvol</th>
            <th scope="col">Mission</th>
            <th scope="col">Status</th>
            <th scope="col">DX</th>
            <th scope="col">Business</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((project: Project, i) => (
              <tr key={i}>
                <td>
                  <label className="wk-field-choice-label">
                    <input data-testid="select-item" type="checkbox" onChange={() => onItemSelect(i)}
                      checked={project.selected || false} name="name1" value="option-1"
                      className="wk-field-choice" />
                    <span className="wk-field-choice-text">{project.name}</span>
                  </label>
                </td>
                <td>
                  {getNestedValuesByKey(project, 'name')}
                </td>
                <td>{getNestedValuesByKey(project, 'mission')}</td>
                <td className="statuses">
                  <div>
                    {switchIcon(project, i)}
                  </div>
                </td>
                <td>{getNestedValuesByKey(project, 'dx')}</td>
                <td>{getNestedValuesByKey(project, 'business')}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
