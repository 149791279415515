import { FC } from 'react';

interface StatusButtonProps {
  title: string;
  description: string;
  count?: number;
  isActive?: boolean;
  iconClass?: string;
  onStatusChange: (isActive: boolean) => void;
}

const StatusButton: FC<StatusButtonProps> = ({ title, description, count, isActive, iconClass, onStatusChange }): JSX.Element => {

  const onItemClick = (): void => {
    onStatusChange(!isActive);
  }

  return (
    <div data-testid="status-button" className={isActive ? 'is-active status-button' : 'status-button'} onClick={onItemClick}>
      <p className="count">{count || 0}</p>
      <div className="button-content">
        {
          iconClass ? <p className="icon">
            <span className={iconClass} aria-hidden="true"></span>
          </p > : null
        }
        <div className="status-texts">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    </div>
  )
}

export default StatusButton;
