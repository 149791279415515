import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import * as config from './config/config.json';
import Header from './components/Header/Header';
import MigrationOverview from './components/MigrationOverview/MigrationOverview';
import SpineMenu from './components/SpineMenu/SpineMenu';
import { User } from './models/user';
import api from './api';

interface AuthError {
  error_message: string;
}

function App(): JSX.Element {

  const [isAuthenticated, setAuthState] = useState(false);
  const [userData, setUserData] = useState<User>();
  const [isAuthorized, setAuthorization] = useState(false);
  const notAuthorisedMessage = 'You currently do not have permissions to use the dashboard. If you need access, please contact the Support Desk.';

  useEffect(() => {
    (async function () {
      try {
        const userInfo = await api.getUserInfo();
        if ((userInfo as unknown as AuthError).error_message) {
          goToLoginPage();
        } else {
          setUserData(userInfo);
          authorizationMethod();
        }
      } catch(e) {
        goToLoginPage();
      } 
    })();
  }, []);

  const goToLoginPage = () => {
    window.location.href = `${window.location.origin}/${config.sso.loginURL}`;
  };

  const authorizationMethod = async () => {
    try {
      const authorizationInfo = await api.isUserAuthorized();
      setAuthState(true);
      if (getAuthorizationInfo(authorizationInfo)) {
        setAuthorization(true);
      } else {
        setAuthorization(false);
      }
    } catch(e) {
      setAuthState(true);
      setAuthorization(false);
    }
  };

  const getAuthorizationInfo = (data: string): boolean => {
    const myString = JSON.stringify(data);
    return !!myString.match(/"Value":"Allow"/g);
  };

  return (
    <div>
      {
        isAuthenticated ? <>
        {
          isAuthorized ? <>
            <Header user={userData} />
            <div className="app-container">
              <Router>
                <SpineMenu />
                <div className="app-content">
                  <Switch>
                    <Route path="/migration-overview" component={MigrationOverview} />
                    <Route path="/">
                      <Redirect to="/migration-overview" />
                    </Route>
                  </Switch>
                </div>
              </Router>
            </div>
          </> : notAuthorisedMessage
        }
        </> : null
      }
    </div>
  );
}

export default App;
