import { FC, useState, useMemo, useEffect } from 'react';
import { PROJECT_STATUSES } from '../../models/project-statuses.model';
import { Project } from '../../models/project.model';
import DataTable from '../DataTable/DataTable';
import Pagination from '../Pagination/Pagination';
import StatusButton from '../StatusButton/StatusButton';
import api from '../../api';

const MigrationOverview: FC = (): JSX.Element => {
  const [filters] = useState<unknown[]>([]);
  const [data, setData] = useState<Project[]>([]);
  const [selectedItems, setSelectedItems] = useState<Project[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(25);
  const [selectedStatus, setSelectedStatus] = useState<PROJECT_STATUSES | 'ALL'>('ALL');
  const [filteredDataCount, setFilteredDataCount] = useState<number>(0);

  const searchByTerm = (projects: Project[], term: string): Project[] => {
    if (!term) {
      return projects
    }

    term = term.toLowerCase();

    return projects.filter((project: Project): boolean => {
      const pubvols = project.pubvols.map(el => el.name.toLowerCase());
      const businesses = project.pubvols.map(el => el.business.toLowerCase());
      const dx = project.pubvols.map(el => el.dx.toLowerCase());
      const missions = project.pubvols.map(el => el.mission.toLowerCase());

      return project.name.toLowerCase().includes(term) ||
        pubvols.some(el => el.includes(term)) || businesses.some(el => el.includes(term)) ||
        dx.some(el => el.includes(term)) || missions.some(el => el.includes(term));
    });
  }

  const dataToDisplay = useMemo(() => {
    const filteredByType = selectedStatus === 'ALL' ? data.slice() : data.filter(el => el.importStatus === selectedStatus);
    const filteredBySearch = searchByTerm(filteredByType, search);
    setFilteredDataCount(filteredBySearch.length);
    const filteredData = filteredBySearch.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
    setSelectedItems(filteredData.filter(el => el.selected));
    return filteredData;
  }, [activePage, search, itemsPerPage, selectedStatus, setFilteredDataCount, data]);

  const sortByName = (first: Project, second: Project): number => {
    const firstName = first.name.toLowerCase();
    const secondName = second.name.toLowerCase();

    if (firstName < secondName) {
      return -1;
    }

    return firstName > secondName ? 1 : 0;
  }

  const getProjects = (): void => {
    api.getProjects().then((res: { data: Project[] }): void => {
      res.data.sort(sortByName);
      setData(res.data);
      setSelectedItems([]);
    });
  }

  useEffect(getProjects, []);

  const onSearchChange = ({ target: { value } }: { target: { value: string } }): void => {
    setActivePage(1);
    setSearch(value);
  }

  const startMigration = (): void => {
    Promise.allSettled(selectedItems.map(selectedItem => {
      if (api.isMissionEndsWith(selectedItem, '-aspen')) {
        return api.startMigrationWithParameters(selectedItem.name, selectedItem.pubvols[0].mission);
      }
      return api.startMigration([selectedItem]);
    }))
      .then(results => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            selectedItems[index].importStatus = PROJECT_STATUSES.IN_PROGRESS
          }
        });
        data.forEach(el => el.selected = false);
        setSelectedItems([]);
      });
  }

  const isMigrationButtonDisabled = (): boolean => {
    return !selectedItems.length || selectedItems.some(el => el.importStatus !== PROJECT_STATUSES.NOT_MIGRATED && el.importStatus !== PROJECT_STATUSES.ERROR);
  }

  const changeSelectedStatus = (status: PROJECT_STATUSES | 'ALL'): void => {
    setActivePage(1);
    setSelectedStatus(status);
  }

  return (
    <div className="migration-overview-view">
      <div className="page-header">
        <div className="title-section">
          <p>Migration Overview</p>
        </div>
        <div className="wk-row">
          <div data-testid="all-button" className="wk-column-12 wk-col-12-tablet wk-col-2-laptop wk-column-6-laptop">
            <StatusButton title="ALL" isActive={selectedStatus === 'ALL'} description="Mercury - Apollo"
              count={data.length} onStatusChange={() => changeSelectedStatus('ALL')} />
          </div>
          <div data-testid="not-migrated-button" className="wk-column-12 wk-col-12-tablet wk-col-2-laptop wk-column-6-laptop">
            <StatusButton title="NOT YET MIGRATED" isActive={selectedStatus === PROJECT_STATUSES.NOT_MIGRATED}
              description="Mercury - Apollo" count={data.filter(el => el.importStatus === PROJECT_STATUSES.NOT_MIGRATED).length}
              iconClass="wk-icon-clock small-icon" onStatusChange={() => changeSelectedStatus(PROJECT_STATUSES.NOT_MIGRATED)} />
          </div>
          <div data-testid="in-progress-button" className="wk-column-12 wk-col-12-tablet wk-col-2-laptop wk-column-6-laptop">
            <StatusButton title="IN PROGRESS" isActive={selectedStatus === PROJECT_STATUSES.IN_PROGRESS}
              description="Mercury - Apollo" count={data.filter(el => el.importStatus === PROJECT_STATUSES.IN_PROGRESS).length}
              iconClass="wk-icon-infinity" onStatusChange={() => changeSelectedStatus(PROJECT_STATUSES.IN_PROGRESS)} />
          </div>
          <div data-testid="error-button" className="wk-column-12 wk-col-12-tablet wk-col-2-laptop wk-column-6-laptop">
            <StatusButton title="ERROR" isActive={selectedStatus === PROJECT_STATUSES.ERROR}
              description="Mercury - Apollo" count={data.filter(el => el.importStatus === PROJECT_STATUSES.ERROR).length}
              iconClass="wk-icon-close-circle small-icon bold-icon" onStatusChange={() => changeSelectedStatus(PROJECT_STATUSES.ERROR)} />
          </div>
          <div data-testid="success-button" className="wk-column-12 wk-col-12-tablet wk-col-2-laptop wk-column-6-laptop">
            <StatusButton title="SUCCESS" isActive={selectedStatus === PROJECT_STATUSES.SUCCESS}
              description="Mercury - Apollo" count={data.filter(el => el.importStatus === PROJECT_STATUSES.SUCCESS).length}
              iconClass="wk-icon-check-circle small-icon bold-icon" onStatusChange={() => changeSelectedStatus(PROJECT_STATUSES.SUCCESS)} />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="filter-section wk-row">
          <div className="wk-col-6-laptop wk-column-6-laptop wk-col-12-tablet wk-column-12">
            <div className="wk-col-6-laptop wk-column-12-laptop wk-column-12">
              <div data-field-inlay-items="1" className="wk-field">
                <div className="wk-field-body">
                  <input data-testid="search-input" type="search" placeholder="Search, pubvol, product name, business…" onChange={onSearchChange}
                    className="wk-field-input" />
                  <div className="wk-field-inlay">
                    <button type="button"
                      className="wk-field-button wk-button wk-button-icon wk-button-icon-large wk-button-small">
                      <span aria-hidden="true" className="wk-icon-search"></span>
                      <span className="wk-sr-only">Submit search</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-section wk-col-6-laptop wk-column-6-laptop wk-col-12-tablet wk-column-12">
            <button data-testid="start-migration-button" type="button" className="wk-button" onClick={startMigration}
              disabled={isMigrationButtonDisabled()}>Start migration</button>
            <div className="filter">
              <span title="funnel" className={filters.length ? 'wk-icon-filled-funnel' : 'wk-icon-funnel'}></span>
              <span className="filter-text">Filters</span>
              <span className="wk-tag wk-tag-blue">{filters.length}</span>
            </div>
          </div>
        </div>
        <DataTable data-testid="data-table" data={dataToDisplay} onSelectionChange={setSelectedItems} />
      </div>
      <Pagination paginationOptions={[10, 25, 50]} allCount={filteredDataCount} activePage={activePage}
        itemsPerPage={itemsPerPage} itemsPerPageChange={setItemsPerPage} onPageChange={setActivePage} />
    </div>
  )
}

export default MigrationOverview;
