import { FC, useState } from 'react';
import { User } from '../../models/user';
import * as config from '../../config/config.json';

interface FishbowlProps {
  user: User | undefined;
}

const Fishbowl: FC<FishbowlProps> = ({ user }): JSX.Element => {
  const [dropdownToggled, setDropdownToggle] = useState(false);

  return (
    <div data-testid="dropdown" className={`wk-dropdown wk-dropdown-right ${dropdownToggled ? 'wk-is-open' : ''}`}>
      <button data-testid="logout-toggle-button" type="button"
        onClick={() => setDropdownToggle(!dropdownToggled)}
        className="wk-fishbowl wk-button wk-dropdown-toggle"
        aria-haspopup="true" aria-expanded="true"
      >
        <span className="wk-sr-only">Open account and organization settings</span>
        <div className="wk-fishbowl-description">
          <span data-testid="username" className="wk-fishbowl-user-name">{user?.username}</span>
        </div>
      </button>
      <div className="wk-dropdown-menu">
        <dl>
          <dd>
            <button data-testid="logout-button" type="button" className="wk-dropdown-item"
              onClick={() => window.location.pathname = config.sso.logoutURL}>
              <span className="wk-icon-logout" aria-hidden="true"></span>
            Logout
          </button>
          </dd>
        </dl>
      </div>
    </div>
  )
};

export default Fishbowl;
