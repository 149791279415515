import { FC } from 'react';

interface PaginationProps {
  allCount: number;
  activePage: number;
  itemsPerPage: number;
  paginationOptions: number[];
  itemsPerPageChange: (count: number) => void;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({ allCount,
  activePage, itemsPerPage, paginationOptions, itemsPerPageChange, onPageChange
}): JSX.Element => {
  const activeClassName = 'wk-is-active';

  const onPaginationChange = ({ target: { value } }: { target: { value: string } }): void => {
    onPageChange(1);
    itemsPerPageChange(+value);
  }

  const getArrayRange = (start: number, end: number): number[] => {
    return Array(end - start + 1).fill(undefined).map((_, idx) => start + idx);
  }

  const switchClassName = (condition: boolean, class1: string, class2: string): string => {
    return condition ? class1 : class2;
  }

  const pagesArray = getArrayRange(1, Math.ceil(allCount / itemsPerPage));

  const getPages = (arr: number[]): JSX.Element[] => {
    return arr.map((val, i) => (<li key={i} className={`wk-pagination-item ${switchClassName(activePage === val, activeClassName, '')}`}>
      <span data-testid="page-button" onClick={() => onPageChange(val)} className="wk-pagination-link" aria-label={`Page ${val}`}>{val}</span>
    </li>));
  }

  const getEllipsedPages = (): JSX.Element | undefined => {
    let firstNumber, lastNumber;

    if (activePage - 3 <= 1) {
      firstNumber = 1;
      lastNumber = 5;
    } else if (activePage + 3 >= pagesArray.length) {
      firstNumber = pagesArray.length - 4;
      lastNumber = pagesArray.length;
    } else {
      firstNumber = activePage - 1;
      lastNumber = activePage + 1;
    }

    return (<>
      {
        activePage > 4 ? (<>
          <li key={0} className={`'wk-pagination-item' ${activePage === 1 ? activeClassName : ''}`}>
            <span data-testid="page-button" onClick={() => onPageChange(1)} className="wk-pagination-link" aria-label="Page 1">1</span>
          </li>
          <li className="wk-pagination-item">
            <span className="wk-pagination-ellipsis">...</span>
          </li>
        </>) : null
      }
      {
        getPages(getArrayRange(firstNumber, lastNumber))
      }
      {
        activePage < pagesArray.length - 3 ? (<>
          <li className="wk-pagination-item">
            <span className="wk-pagination-ellipsis">...</span>
          </li>
          <li key={pagesArray.length}
            className={`wk-pagination-item ${switchClassName(activePage === pagesArray.length, activeClassName, '')}`}>
            <span
              data-testid="page-button"
              onClick={() => onPageChange(pagesArray.length)}
              className="wk-pagination-link" aria-label={`Page ${pagesArray.length}`}>
              {pagesArray.length}
            </span>
          </li>
        </>) : null
      }
    </>)
  }

  const getDisplayedInfo = (): string => {
    const startNumber = !allCount ? 0 : itemsPerPage * (activePage - 1) + 1;
    const endNumber = itemsPerPage * activePage < allCount ? itemsPerPage * activePage : allCount;
    return `${startNumber} - ${endNumber} of ${allCount} results`;
  }

  return (
    <nav className="wk-pagination-bar">
      <div className="wk-pagination-results" data-testid="info-section">
        {getDisplayedInfo()}
      </div>
      <div className="wk-field wk-field-secondary wk-field-small wk-field-custom-select">
        <div className="wk-field-header">
          <label className="wk-field-label wk-field-label-hidden" htmlFor="id-lQdqFZ">Select number of results per page</label>
        </div>
        <div className="wk-field-body">
          <div className="wk-field-select-container">
            <select data-testid="pagination-options" className="wk-field-select" id="id-lQdqFZ" value={itemsPerPage} onChange={onPaginationChange}>
              {
                paginationOptions.map(option => (<option key={option} value={option}>{option}</option>))
              }
            </select>
          </div>
        </div>
      </div>
      <ul className="wk-pagination" data-testid="pages">
        <li data-testid="previous-button" className={`wk-pagination-item ${switchClassName(activePage === 1, 'wk-disabled disabled', '')}`}
          onClick={() => onPageChange(activePage - 1)}>
          <span className="wk-pagination-link" aria-label="Previous page" aria-disabled={activePage === 1}>
            <span className="wk-icon-arrow-left"></span>
          </span>
        </li>
        {
          pagesArray.length <= 7 ? getPages(pagesArray) : <>
            {
              getEllipsedPages()
            }
          </>
        }
        <li data-testid="next-button" className={`wk-pagination-item ${switchClassName(activePage === pagesArray.length, 'wk-disabled disabled', '')}`}
          onClick={() => onPageChange(activePage + 1)}>
          <span className="wk-pagination-link" aria-label="Next page" aria-disabled={activePage === pagesArray.length}>
            <span className="wk-icon-arrow-right"></span>
          </span>
        </li>
      </ul>
    </nav>
  )
};

export default Pagination;
