import axios from 'axios';
import * as config from '../config/config.json';
import { Project } from '../models/project.model';
import { User } from '../models/user';

interface StartMigrationParameters {
  [key: number]: {
    product: string;
  }
}

class API {

  getUserInfo = async (): Promise<User> => {
    return fetch(config.sso.userInfoURL).then(res => res.json());
  }

  getLogFile = async (path: string): Promise<{ file: Promise<Blob>, name: string }> => {
    return fetch(`${config.projects.logURL}?path=${path}`).then(res => ({
      file: res.blob(),
      name: res.headers.get('Content-Disposition')?.split('"')[1]
    })) as Promise<{ file: Promise<Blob>, name: string }>;
  }

  getProjects = async (): Promise<{ data: Project[] }> => {
    return fetch(config.projects.URL).then(res => res.json());
  }

  startMigration = (projects: Project[]): Promise<unknown> => {
    const parameters: StartMigrationParameters = {};
    projects.forEach((project: Project, index: number): void => {
      parameters[index + 1] = {
        product: project.name
      };
    });

    return axios.post(config.startMigration.URL, { parameters }, {
      withCredentials: true,
      headers: config.startMigration.headers,
    });
  }

  isUserAuthorized = async (): Promise<any> => {
    const params = {
      $format: 'json',
      application: 'Migration Publishing Dashboard'
    };
    return axios.get(config.bua.getUserProfile, { params });
  }
  
  startMigrationWithParameters = (projectName: string, missionName: string): Promise<unknown> => {
    const parameters = {
      1: { 'product': projectName },
      5: { 'parameters': `7^custom=target%3Drecover%3Bmission%3D${missionName}` }
    };
    return axios.post(config.startMigration.URL, { parameters }, {
      withCredentials: true,
      headers: config.startMigration.headers,
    });
  };

  isMissionEndsWith = (project: Project, missionPart: string): boolean => {
    if (
      project.pubvols &&
      project.pubvols[0] &&
      project.pubvols[0].mission &&
      project.pubvols[0].mission.toLowerCase().endsWith(missionPart)
    ) {
      return true;
    }
    return false;
  };
}

export default new API();
